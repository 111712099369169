<template>
    <div>
        <div class="row" v-if="isFound">
            <div class="col-12">
                <div class="row" v-if="!isLoad">
                    <div  class="col-12" v-if="questionnaire.length">
                        <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
                    </div>
                    <div class="col-12 text-center py-3" v-if="questionnaire.length">
                        <div class="table-responsive mw-100 pr-2 ml-0">
                            <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="questionnaire" :page="1" :perPage="10" :waitForPager="true">
                                <template name="default" slot-scope="{ row, index }">
                                    <tr>
                                        <td>{{ index+1 }}</td>
                                        <td>{{ row.title }}</td>
                                        <td>
                                            <router-link class="btn btn-sm btn-primary mr-2" :to="{name: 'ClosedQuestionnaireSubmissionList', params: {idCourse: paramsId, idQuestionnaire: row.id}}">{{row.submission}}</router-link>
                                        </td>
                                        <td>{{ row.created.name }}</td>
                                        <td>
                                            <button @click="changeStatusAlert(row.id)" class="btn btn-sm" :class="{'btn-success' : row.publish, 'btn-danger' : !row.publish }"><i class="fas" :class="{'fa-check' : row.publish, 'fa-times' : !row.publish }"></i></button>
                                        </td>
                                        <td>
                                            <router-link class=" mr-2" :to="{name: 'ReflectionClosedTaskCoursesAdmin', params: {idCourse: paramsId, idQuestionnaire: row.id}}"><i class="fas fa-info text-info"></i></router-link>
                                            <router-link class=" mr-2" :to="{name: 'ReflectionClosedEditCoursesAdmin', params: {idCourse: paramsId, idQuestionnaire: row.id}}"><i class="fas fa-edit text-warning"></i></router-link>
                                            <a href="javascript:void(0)" @click="deleteAlert(row.id)" class=""><i class="fas fa-trash text-danger"></i></a>
                                        </td>
                                    </tr>
                                </template>
                                <template name="no-result">
                                    Nothing to see here
                                </template>
                            </datatable>
                        </div>
                        <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                    </div>
                    <div class="col-12 text-center" v-if="!questionnaire.length">
                        <img :src="'/images/empty.png'" alt="" width="300">
                        <h1 class="text-muted">Data don't exist</h1>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="vertical-wrapper pt-lg--7 pt-3">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center default-page">
                            <div class="card border-0 text-center d-block">
                                <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            questionnaire: [],
            isLoad: true,
            isFound: true,
            tableFilter: '',
            page: 1,
            columns: [
                {label: 'No', field: 'id',headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center', filterable: false, sortable: false},
                {label: 'Title', field: 'title', headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center wide-column'},
                {label: 'Submission', field: 'submission', headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center wide-column'},
                {label: 'Created By', field: 'created_by.name', headerClass: 'border-0 bg-current text-white py-3', class: 'product-p text-center'},
                {label: 'Publish', field: 'publish', headerClass: 'border-0 bg-current text-white py-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Option', headerClass: 'border-0 bg-current text-white py-3', class: 'product-remove text-center', filterable: false, sortable: false},
            ],
        }
    },
    created(){
        this.getQuestionnaire()
    },
    methods:{
        async getQuestionnaire(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire?type=2&slug=` + this.paramsId, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.questionnaire = res.data.data
                this.isLoad = false
            }).catch(err => {
                if (err.response.status == 404) {
                    this.isFound = false
                }
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        slug: this.paramsId,
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Questionnaire - Closed!',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getQuestionnaire()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Questionnaire - Closed!',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        changeStatusAlert(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Change Status it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        slug: this.paramsId,
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/reflection/questionnaire/publish`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Questionnaire Closed!',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getQuestionnaire()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Questionnaire Closed!',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}

</script>